import { ItemDetails } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface PersonDetailsViewProps {
  personId: string;
  proposalId: string | null;
  onChanged: (person: ItemDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
  chooseItem?: () => Promise<void>;
}

const PersonDetailsView: FC<PersonDetailsViewProps> = ({
  personId,
  proposalId,
  onChanged,
  onSaveStateChanged,
  deleteItem,
  chooseItem,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpContentLibraryRead(personId, {
      // @ts-ignore
      query: { proposal_id: proposalId, kind: "person" },
    });
    return result.data;
  }, [personId]);

  return (
    <BaseItemDetailsView<ItemDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpContentLibraryUpdate(personId, details, {
          // @ts-ignore
          query: { proposal_id: proposalId, kind: "person" },
        });
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      deleteItem={deleteItem}
      chooseItem={chooseItem}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Name">
            <Input
              placeholder="The name of this person"
              value={details.name ?? ""}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
            />
          </LabeledFormInput>
          <LabeledFormInput label="Title">
            <Input
              placeholder="What this person's title is"
              value={details.title ?? ""}
              onChange={(e) =>
                setDetails({ ...details, title: e.target.value })
              }
            />
          </LabeledFormInput>
          <LabeledFormInput label="Bio" className="grow overflow-hidden">
            <CollaborativeTextArea
              placeholder="A detailed description of this person"
              className="h-[600px]"
              docId={details.content_id!}
              key={details.content_id}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default PersonDetailsView;
