import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import { debounce } from "lodash";
import { useConfirm } from "providers/AlertProvider";
import { useRef, useState } from "react";
import { message_from_exception } from "utils";

interface BaseItemType {
  id?: string;
}

interface BaseItemDetailsViewProps<ItemType extends BaseItemType> {
  details: ItemType | null;
  setDetails: (details: ItemType) => void;
  saveItem: (details: ItemType) => Promise<void>;
  onChanged: (details: ItemType) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
  // This is a choose button that will be shown if the item
  // is selected. Used in circumstances like choosing a base
  // item for a section.
  chooseItem?: () => Promise<void>;
  loadingError?: any;

  renderChildren: (
    details: ItemType,
    setDetails: (details: ItemType) => void
  ) => React.ReactNode;
}

const BaseItemDetailsView = <ItemType extends BaseItemType>({
  details,
  saveItem,
  setDetails: doSetDetails,
  onChanged,
  onSaveStateChanged,
  deleteItem: remove,
  chooseItem,
  loadingError,
  renderChildren,
}: BaseItemDetailsViewProps<ItemType>) => {
  const [saveState, doSetSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const confirm = useConfirm();

  const setSaveState = (state: SaveState) => {
    doSetSaveState(state);
    onSaveStateChanged?.(state);
  };

  const debouncedSave = useRef(
    debounce(async (details: ItemType) => {
      try {
        await saveItem(details);
        setSaveState("saved");
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  const setDetails = (details: ItemType) => {
    doSetDetails(details);
    setSaveState("saving");
    onChanged(details);
    debouncedSave.current(details);
  };

  const tryAgain = () => {
    if (details) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(details);
    }
  };

  const handleDelete = async () => {
    if (!details) return;
    const result = await confirm("Are you sure you want to delete this?", {
      yesText: "Delete",
      yesDestructive: true,
    });
    if (result) {
      try {
        await remove();
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }
  };

  const handleChoose = async () => {
    if (!details) return;
    if (!chooseItem) return;
    try {
      await chooseItem();
    } catch (e) {
      setSaveState("error");
      setSaveError(message_from_exception(e));
    }
  };

  return (
    <AsyncLoadedDiv
      value={details}
      error={!!loadingError ? message_from_exception(loadingError) : undefined}
      className="grow p-lg pt-0 overflow-y-auto flex"
      whileLoaded={(details) => (
        <Rows className="grow">
          <Columns className="items-center sticky top-0 pt-lg pb-md bg-background">
            <h1 className="text-lg font-semibold grow shrink-0">Details</h1>
            {saveError && (
              <p className="grow text-destructive text-right pr-md">
                {saveError}
              </p>
            )}
            <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
          </Columns>
          <Rows className="grow gap-md justify-start">
            <Scrollable>
              <Rows className="grow gap-md justify-start">
                {renderChildren(details, setDetails)}
              </Rows>
            </Scrollable>
            <Columns className="justify-end shrink-0 gap-lg">
              <Button
                text="Delete"
                icon="trash"
                variant="destructive"
                onClick={handleDelete}
              />
              {chooseItem && (
                <Button
                  text="Choose"
                  icon="check"
                  variant="solid"
                  onClick={handleChoose}
                />
              )}
            </Columns>
          </Rows>
        </Rows>
      )}
    />
  );
};

export default BaseItemDetailsView;
