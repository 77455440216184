import { useEffect, useMemo, useState } from "react";

import { LibraryItem } from "hooks/useContentLibrary";
import LoadableView from "../containers/LoadableView";
import { useApiClient } from "providers/ApiClientProvider";
import Button from "../Button";
import Overlay from "../containers/overlays/Overlay";
import ContentLibraryView from "components/content-library/ContentLibraryView";
import CollaborativeTextArea from "./CollaborativeTextArea";
import { ItemDetails } from "api/Api";
import Columns from "../containers/Columns";

interface ContentLibraryItemPickerProps {
  proposalId: string | null;
  selectedItemId: string | null | undefined;
  onSelect: (itemId: string | null) => void;
  defaultSearchQuery?: string;
  contentEmbedding?: number[] | null;
}

const ContentLibraryItemPicker = ({
  proposalId,
  selectedItemId,
  onSelect,
  defaultSearchQuery,
  contentEmbedding,
}: ContentLibraryItemPickerProps) => {
  const apiClient = useApiClient();
  const [baseContentItem, setBaseContentItem] = useState<
    ItemDetails | null | undefined
  >(undefined);
  const [pickingItem, setPickingItem] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedItemId) {
      setBaseContentItem(null);
      return;
    }

    setBaseContentItem(undefined);
    const getItem = async () => {
      const response = await apiClient.rfp.rfpContentLibraryRead(
        selectedItemId
      );
      setBaseContentItem(response.data);
    };
    getItem();
  }, [apiClient.rfp, selectedItemId]);

  const chooseItem = useMemo(
    () => async (item: LibraryItem) => {
      await onSelect(item.id!);
      setPickingItem(false);
    },
    [onSelect]
  );

  const clearItem = () => {
    setBaseContentItem(null);
    onSelect(null);
  };

  return (
    <LoadableView
      className="grow border rounded-sm flex relative overflow-hidden"
      isLoading={baseContentItem === undefined}
    >
      {baseContentItem === null && (
        <div className="text-secondary grow flex items-center justify-center text-sm">
          None
        </div>
      )}
      {baseContentItem && baseContentItem.content_id && (
        <div className="grow flex overflow-hidden">
          <CollaborativeTextArea
            docId={baseContentItem.content_id}
            className="grow"
            editable={false}
            key={baseContentItem.content_id}
          />
        </div>
      )}
      <Columns className="absolute -bottom-thin -right-thin gap-sm bg-background p-sm border rounded-sm">
        <Button
          onClick={() => setPickingItem(true)}
          text="Select"
          variant="solid-secondary"
        />
        {baseContentItem && (
          <Button
            onClick={clearItem}
            icon="circle-xmark"
            className="text-destructive"
          />
        )}
      </Columns>
      {pickingItem && (
        <Overlay
          onClose={() => setPickingItem(false)}
          title="Select Base"
          variant="bordered"
          className="grow"
        >
          <ContentLibraryView
            proposalId={proposalId}
            size="full"
            initialItemId={selectedItemId ?? undefined}
            initialCategory="project"
            chooseItem={chooseItem}
            defaultSearchQuery={defaultSearchQuery}
            contentEmbedding={contentEmbedding}
          />
        </Overlay>
      )}
    </LoadableView>
  );
};

export default ContentLibraryItemPicker;
