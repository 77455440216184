import { useEditorRef } from "@udecode/plate-common";
import ContentLibraryView from "components/content-library/ContentLibraryView";
import { LibraryItem } from "hooks/useContentLibrary";
import useContentLibraryActions from "hooks/useContentLibraryActions";
import { getSection } from "lib/plate/getPreviousSection";
import { useAlert } from "providers/AlertProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";

const ContentLibraryPanel: FC = () => {
  const editor = useEditorRef();
  const { details } = useProposalData();
  const { activeSection, setDroppingSection } = useEditorDocData();

  const { insertItem } = useContentLibraryActions(details.id);
  const activeSectionRef = useRef(activeSection);
  const alert = useAlert();

  useEffect(() => {
    activeSectionRef.current = activeSection;
  }, [activeSection]);

  const handleInsert = useCallback(
    async (item: LibraryItem) => {
      if (!activeSectionRef.current) {
        await alert("Please click a section first", {
          body: "This will be where the content is inserted.",
        });
        return;
      }
      const sectionNode = getSection(activeSectionRef.current.id, editor);
      if (!sectionNode) {
        toast.error("Failed to find section to insert into");
        return;
      }
      await insertItem(
        item.id,
        item.kind,
        sectionNode,
        activeSectionRef.current
      );
    },
    [editor, insertItem, alert]
  );

  const handleInsertPreview = useCallback(
    (item: LibraryItem | null) => {
      if (!item) {
        setDroppingSection(null);
        return;
      }
      if (!activeSectionRef.current) {
        setDroppingSection(null);
        return;
      }

      setDroppingSection({
        id: activeSectionRef.current.id,
        name: activeSectionRef.current.name,
        location: "below",
        contentEmbedding: activeSectionRef.current.contentEmbedding,
      });
    },
    [setDroppingSection, activeSectionRef]
  );

  return (
    <ContentLibraryView
      size="compact"
      proposalId={details.id}
      initialCategory={null}
      onInsert={handleInsert}
      onInsertPreview={handleInsertPreview}
    />
  );
};

export default ContentLibraryPanel;
