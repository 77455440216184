import { useDraggable } from "@dnd-kit/core";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import { CellProps } from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import {
  Approach,
  Category,
  CoverLetter,
  LibraryItem,
  Overview,
  PastProposal,
  Person,
  Project,
  Subcontractor,
} from "hooks/useContentLibrary";
import { cn } from "lib/utils";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, useEffect, useMemo, useState } from "react";

interface ContentLibraryListItemProps {
  item: LibraryItem;
  highlight?: boolean;
  activeCategory: Category;
  singularPropertyName: string;
  selectedItemId?: string;
  onSelected: (item: LibraryItem) => void;
  onInsert?: (item: LibraryItem) => Promise<void>;
  onInsertPreview?: (item: LibraryItem | null) => void;
  Cell: FC<CellProps>;
}

const ContentLibraryListItem: FC<ContentLibraryListItemProps> = ({
  activeCategory,
  item,
  Cell,
  selectedItemId,
  singularPropertyName,
  highlight = false,
  onSelected,
  onInsert,
  onInsertPreview,
}) => {
  const { title, subtitle } = getLibraryItemInfo(item, activeCategory);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: activeCategory + "_" + item.id,
    data: {},
  });
  const [previewing, setPreviewing] = useState(false);
  const { isStaff } = useAuthenticatedUser();

  useEffect(() => {
    if (previewing) {
      onInsertPreview?.(item);
    } else {
      onInsertPreview?.(null);
    }
  }, [previewing, onInsertPreview, item]);

  let insertButton = useMemo(() => {
    if (!onInsert) return null;

    return (
      <Button
        icon="arrow-left"
        iconVariant="solid"
        variant="outline-full"
        className={cn(
          "text-primary border-primary hover:bg-background rounded",
          highlight && "bg-primary text-background"
        )}
        tooltip="Insert Content"
        onClick={async () => {
          await onInsert(item);
          setPreviewing(false);
        }}
        onMouseEnter={() => setPreviewing(true)}
        onMouseLeave={() => setPreviewing(false)}
      />
    );
  }, [onInsert, item, highlight]);

  return (
    <Cell
      className={cn(
        "px-sm hover:bg-background-selected cursor-pointer min-h-[4em]",
        !title && "text-secondary",
        selectedItemId === item.id && "bg-background-selected",
        onInsert && "cursor-grab"
      )}
      onClick={() => onSelected(item)}
      cellRef={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <Columns className="shrink-0 mr-md">{insertButton}</Columns>
      <Rows className={cn("ml-xs grow", isDragging ? "opacity-20" : "")}>
        <div>{title || `New ${singularPropertyName}`}</div>
        <div className="text-sm text-secondary">{subtitle}</div>
      </Rows>
      {isStaff && item.distance && item.distance < 1 && (
        <div className="shrink-0 text-xs text-secondary">
          {Math.round((1 - item.distance) * 1000) / 10}
        </div>
      )}
    </Cell>
  );
};

export const getLibraryItemInfo = (
  item: LibraryItem,
  activeCategory: Category
) => {
  let title: string;
  let subtitle = null;
  switch (item.kind) {
    case "project":
      const project = item as Project;
      title = project.title;
      if (project.startMonth && project.startYear) {
        title += ` (${project.startMonth}/${project.startYear})`;
      }
      if (project.customer && project.location) {
        subtitle = `${project.customer} - ${project.location}`;
      } else if (project.customer) {
        subtitle = project.customer;
      } else if (project.location) {
        subtitle = project.location;
      } else {
        subtitle = "-";
      }
      break;
    case "person":
      const person = item as Person;
      title = person.name;
      if (person.title) {
        subtitle = person.title;
      } else {
        subtitle = "-";
      }
      break;
    case "overview":
      const overview = item as Overview;
      title = overview.topic;
      break;
    case "cover_letter":
      const coverLetter = item as CoverLetter;
      title = coverLetter.topic;
      break;
    case "subcontractor":
      const subcontractor = item as Subcontractor;
      title = subcontractor.name;
      break;
    case "past_proposal":
      const pastProposal = item as PastProposal;
      title = pastProposal.name;
      break;
    case "approach":
      const approach = item as Approach;
      title = approach.title;
      break;
  }
  return { title, subtitle };
};

export default ContentLibraryListItem;
