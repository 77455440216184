import { AxiosResponse } from "axios";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import { FlowContainerRef } from "components/common/containers/flow-container/FlowContainer";
import Overlay from "components/common/containers/overlays/Overlay";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import Toggle from "components/common/forms/Toggle";
import ContentLibraryProjectDetails from "components/content-library/ContentLibraryDetails";
import { getLibraryItemInfo } from "components/content-library/ContentLibraryListItem";
import useContentLibrary, {
  Category,
  LibraryItem,
  singularNameFromCategory,
} from "hooks/useContentLibrary";
import { useApiClient } from "providers/ApiClientProvider";
import { useImperativeHandle } from "react";

interface StepConfirmation {
  confirmed: boolean;
  setConfirmed: (value: boolean) => void;
}

export interface ContentItemFlowStepRef {
  refresh: () => void;
}

interface ContentItemFlowStepProps {
  proposalId: string;
  flowRef: FlowContainerRef | null;
  confirmation?: StepConfirmation;
  customCreateAssociationEndpoint?: (
    subcontractorId: string,
    proposalId: string
  ) => Promise<AxiosResponse<any>>;
  customDeleteAssociationEndpoint?: (
    subcontractorId: string,
    proposalId: string
  ) => Promise<AxiosResponse<any>>;
  libraryCategory: Category | "past_proposal";
  onGoNext?: () => void;
  onSelectItem?: (item: LibraryItem) => void;
  onAddItem?: () => void;
  continueText?: string;
  stepRef?: React.Ref<ContentItemFlowStepRef>;
}

const ContentItemFlowStep = ({
  proposalId,
  flowRef,
  confirmation,
  customCreateAssociationEndpoint,
  customDeleteAssociationEndpoint,
  onSelectItem,
  onAddItem,
  libraryCategory,
  continueText = "Continue",
  onGoNext,
  stepRef,
}: ContentItemFlowStepProps) => {
  const library = useContentLibrary(libraryCategory, undefined, proposalId);
  const apiClient = useApiClient();
  const createAssociationEndpoint =
    customCreateAssociationEndpoint ??
    apiClient.rfp.rfpContentLibraryAssociationCreate;
  const deleteAssociationEndpoint =
    customDeleteAssociationEndpoint ??
    apiClient.rfp.rfpContentLibraryAssociationDelete;

  useImperativeHandle(stepRef, () => ({
    refresh: () => library.activePaginatedData.refresh(),
  }));

  const toggleItem = async (item: LibraryItem, toValue: boolean) => {
    if (toValue) {
      await createAssociationEndpoint(item.id!, proposalId);
    } else {
      await deleteAssociationEndpoint(item.id!, proposalId);
    }
    library.updateItem({ ...item, in_proposal: toValue });
  };

  const goNext = () => {
    if (onGoNext) {
      onGoNext();
    } else {
      flowRef?.goNext();
    }
  };

  if (confirmation && !confirmation.confirmed) {
    return (
      <Columns className="gap-xl mt-xl shrink-0">
        <Button
          icon="check"
          text="Yes"
          variant="solid"
          size="large"
          className="bg-[#7CB632] w-[110px]"
          onClick={() => confirmation.setConfirmed(true)}
        />
        <Button
          icon="xmark"
          text="No"
          variant="solid"
          size="large"
          className="bg-destructive w-[110px]"
          onClick={goNext}
        />
      </Columns>
    );
  }

  const hasItemInProposal =
    library.activeItems?.some((item) => item.in_proposal) ?? false;

  return (
    <Rows className="grow gap-lg mt-lg w-full">
      <PaginatedTableView
        className="grow w-full mx-auto"
        scrollableClassName="border-t"
        searchable={true}
        rowSeparators={true}
        results={library.activeItems}
        paginatedData={library.activePaginatedData}
        onSelect={(item) =>
          onSelectItem ? onSelectItem(item) : library.setSelectedItem(item)
        }
        columns={[
          {
            size: "min",
          },
          {},
        ]}
        renderRow={(item, Cell, Row) => {
          const { title, subtitle } = getLibraryItemInfo(item, libraryCategory);
          return (
            <Row key={item.id!}>
              <Cell className="pr-0">
                <Toggle
                  toggleType="checkbox"
                  on={item.in_proposal ?? false}
                  onToggle={(on) => toggleItem(item, on)}
                  size="xlarge"
                />
              </Cell>
              <Cell>
                <Rows className="ml-xs">
                  {title ? title : "-"}
                  <div className="text-sm text-secondary">
                    {subtitle ? subtitle : "-"}
                  </div>
                </Rows>
              </Cell>
            </Row>
          );
        }}
        renderNoResultsFound={() => (
          <>
            <Spacer />
            <Button
              icon="plus"
              text={"Add " + singularNameFromCategory(libraryCategory)}
              className="text-lg w-[260px] mx-auto"
              variant="solid-secondary"
              size="large"
              onClick={() => (onAddItem ? onAddItem() : library.addItem())}
            />
            <Spacer />
          </>
        )}
      />
      <Columns className="items-center gap-md shrink-0">
        {!library.activePaginatedData.noResultsFound && (
          <Button
            icon="plus"
            text={"Add " + singularNameFromCategory(libraryCategory)}
            className="text-primary text-lg"
            size="large"
            onClick={() => (onAddItem ? onAddItem() : library.addItem())}
          />
        )}
        <Spacer />
        {!hasItemInProposal && <Button text="Skip" onClick={goNext} />}
        <Button
          text={continueText}
          disabled={!hasItemInProposal}
          variant="solid"
          size="large"
          className="w-[150px]"
          onClick={goNext}
        />
        {library.activePaginatedData.noResultsFound && <Spacer />}
      </Columns>
      {library.selectedItem && (
        <Overlay
          onClose={() => library.setSelectedItem(null)}
          className="w-full"
        >
          <Columns>
            <ContentLibraryProjectDetails
              proposalId={proposalId}
              activeCategory={libraryCategory}
              selectedItem={library.selectedItem}
              setSelectedItemSaveState={library.setSelectedItemSaveState}
              updateItem={library.updateItem}
              deleteItem={library.deleteItem}
            />
          </Columns>
        </Overlay>
      )}
    </Rows>
  );
};

export default ContentItemFlowStep;
