import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import Icon from "components/common/Icon";
import { Category, LibraryItem } from "hooks/useContentLibrary";
import { cn } from "lib/utils";
import { ReactNode, useMemo } from "react";
import ProjectDetailsView from "./ProjectDetailsView";
import PersonDetailsView from "./PeopleDetailsView";
import OverviewDetailsView from "./OverviewDetailsView";
import CoverLetterDetailsView from "./CoverLetterDetailsView";
import SubcontractorDetailsView from "./SubcontractorDetailsView";
import ApproachDetailsView from "./ApproachDetailsView";

interface ContentLibraryProjectDetailsProps {
  proposalId: string | null;
  activeCategory: Category;
  selectedItem: LibraryItem | null;
  setSelectedItemSaveState: (state: SaveState) => void;
  updateItem: (item: LibraryItem) => void;
  deleteItem: () => Promise<void>;
  // This is a choose button that will be shown if the item
  // is selected. Used in circumstances like choosing a base
  // item for a section.
  chooseItem?: (item: LibraryItem) => Promise<void>;
  className?: string;
}

const ContentLibraryProjectDetails: React.FC<
  ContentLibraryProjectDetailsProps
> = ({
  activeCategory,
  selectedItem,
  proposalId,
  setSelectedItemSaveState: setSelectedProjectSaveState,
  updateItem,
  deleteItem,
  chooseItem: doChooseItem,
  className,
}) => {
  const chooseItem = useMemo(() => {
    if (!doChooseItem) return undefined;
    return async () => {
      if (!selectedItem) return;
      await doChooseItem(selectedItem);
    };
  }, [doChooseItem, selectedItem]);
  let content: ReactNode = null;
  if (selectedItem) {
    switch (selectedItem.kind) {
      case "project":
        content = (
          <ProjectDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            projectId={selectedItem.id}
            deleteItem={deleteItem}
            chooseItem={chooseItem}
            onChanged={(project) =>
              updateItem({
                kind: project.kind as any,
                id: project.id!,
                title: project.title ?? "",
                customer: project.customer ?? "",
                location: project.location ?? "",
                startMonth: project.start_month ?? null,
                startYear: project.start_year ?? null,
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "person":
        content = (
          <PersonDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            personId={selectedItem.id}
            deleteItem={deleteItem}
            chooseItem={chooseItem}
            onChanged={(person) =>
              updateItem({
                kind: person.kind as any,
                id: person.id!,
                name: person.name ?? "",
                title: person.title ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "overview":
        content = (
          <OverviewDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            overviewId={selectedItem.id}
            deleteItem={deleteItem}
            chooseItem={chooseItem}
            onChanged={(overview) =>
              updateItem({
                kind: overview.kind as any,
                id: overview.id!,
                topic: overview.topic ?? "",
                created_at: overview.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "cover_letter":
        content = (
          <CoverLetterDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            coverLetterId={selectedItem.id}
            deleteItem={deleteItem}
            chooseItem={chooseItem}
            onChanged={(coverLetter) =>
              updateItem({
                kind: coverLetter.kind as any,
                id: coverLetter.id!,
                topic: coverLetter.topic ?? "",
                created_at: coverLetter.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "subcontractor":
        content = (
          <SubcontractorDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            subcontractorId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(subcontractor) =>
              updateItem({
                kind: subcontractor.kind as any,
                id: subcontractor.id!,
                name: subcontractor.name ?? "",
                created_at: subcontractor.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "approach":
        content = (
          <ApproachDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            approachId={selectedItem.id}
            deleteItem={deleteItem}
            chooseItem={chooseItem}
            onChanged={(approach) =>
              updateItem({
                kind: approach.kind as any,
                id: approach.id!,
                title: approach.title ?? "",
                created_at: approach.created_at ?? "",
              })
            }
          />
        );
        break;
    }
  } else {
    return <NoItemSelected />;
  }

  return <Rows className={cn("basis-0 grow-[2]", className)}>{content}</Rows>;
};
const NoItemSelected = () => {
  return (
    <CenteredContainer className="border-l basis-0 grow-[2]">
      <Rows className="items-center gap-md text-secondary">
        <Icon name="square-left" size="xlarge" variant="solid" />
        <p className="text-center">
          Select an item
          <br />
          from the list on the left
        </p>
      </Rows>
    </CenteredContainer>
  );
};

export default ContentLibraryProjectDetails;
