import { useEffect, useMemo, useState } from "react";
import ContentLibraryProjectDetails from "./ContentLibraryDetails";
import useContentLibrary, {
  Category,
  LibraryItem,
  SEARCH_MODES,
  SearchMode,
} from "hooks/useContentLibrary";
import Columns from "components/common/containers/Columns";
import ContentLibraryNavigation from "./ContentLibraryNavigation";
import Overlay from "components/common/containers/overlays/Overlay";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Button from "components/common/Button";
import { useChoose } from "providers/AlertProvider";

interface ContentLibraryViewProps {
  proposalId: string | null;
  initialCategory: Category | null;
  initialItemId?: string;
  // Full: Navigation and content side by side
  // Compact: Navigation full width, content in modal
  size: "full" | "compact";
  onInsert?: (item: LibraryItem) => Promise<void>;
  onInsertPreview?: (item: LibraryItem | null) => void;
  onCategoryChange?: (category: Category) => void;
  onSelectedItemChange?: (item: LibraryItem | null) => void;

  chooseItem?: (item: LibraryItem) => Promise<void>;

  defaultSearchQuery?: string;
  contentEmbedding?: number[] | null;
}

const ContentLibraryView: React.FC<ContentLibraryViewProps> = ({
  proposalId,
  size,
  initialItemId,
  initialCategory,
  onInsert,
  onInsertPreview,
  onCategoryChange,
  onSelectedItemChange,
  chooseItem,
  defaultSearchQuery,
  contentEmbedding,
}) => {
  const [searchMode, setSearchMode] = useState<SearchMode>(SEARCH_MODES[0]);
  const {
    activeCategory,
    activeItems,
    activePaginatedData,
    selectedItem,
    setActiveCategory,
    setSelectedItemSaveState,
    setSelectedItem,
    addItem,
    updateItem,
    deleteItem,
  } = useContentLibrary(
    initialCategory ?? "project",
    initialItemId,
    proposalId,
    searchMode.query,
    defaultSearchQuery,
    contentEmbedding
  );
  const { isStaff } = useAuthenticatedUser();

  useEffect(() => {
    if (onCategoryChange) {
      onCategoryChange(activeCategory);
    }
  }, [activeCategory, onCategoryChange]);

  useEffect(() => {
    if (onSelectedItemChange) {
      onSelectedItemChange(selectedItem);
    }
  }, [selectedItem, onSelectedItemChange]);

  const itemDetails = useMemo(
    () => (
      <ContentLibraryProjectDetails
        className={size === "full" ? "border-l" : ""}
        proposalId={proposalId}
        activeCategory={activeCategory}
        selectedItem={selectedItem}
        setSelectedItemSaveState={setSelectedItemSaveState}
        updateItem={updateItem}
        deleteItem={deleteItem}
        chooseItem={chooseItem}
      />
    ),
    [
      activeCategory,
      deleteItem,
      proposalId,
      selectedItem,
      setSelectedItemSaveState,
      size,
      updateItem,
      chooseItem,
    ]
  );

  const choose = useChoose();

  return (
    <Columns className="grow relative">
      <ContentLibraryNavigation
        items={activeItems}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        paginatedData={activePaginatedData}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        addItem={addItem}
        onInsert={onInsert}
        onInsertPreview={onInsertPreview}
      />
      {size === "full"
        ? itemDetails
        : selectedItem && (
            <Overlay onClose={() => setSelectedItem(null)} className="w-full">
              <Columns>{itemDetails}</Columns>
            </Overlay>
          )}
      {isStaff && (
        <Button
          icon="gear"
          text={searchMode.name}
          className="absolute bottom-md left-md text-xs admin text-primary"
          onClick={async () => {
            const choice = await choose("Choose search type", {
              choices: SEARCH_MODES.map((mode) => ({
                text: mode.name,
                id: mode.query,
              })),
              dismissId: "cancel",
            });
            if (choice !== "cancel") {
              setSearchMode(
                SEARCH_MODES.find((mode) => mode.query === choice)!
              );
            }
          }}
        />
      )}
    </Columns>
  );
};

export default ContentLibraryView;
