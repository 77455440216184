import { FC } from "react";
import { cn } from "lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { FrontendUser } from "types/FrontendUser";

const avatarVariants = cva(
  "rounded-full inline-block aspect-square inline-flex items-center justify-center select-none",
  {
    variants: {
      size: {
        DEFAULT: "h-2xl text-sm",
        small: "h-lg text-2xs",
      },
      userType: {
        DEFAULT: "text-background",
        bot: "text-background",
      },
    },
    defaultVariants: {
      size: "DEFAULT",
      userType: "DEFAULT",
    },
  }
);
interface OdoUserAvatarProps extends VariantProps<typeof avatarVariants> {
  user?: FrontendUser | null;
  text?: string;
  className?: string;
}

const OdoUserAvatar: FC<OdoUserAvatarProps> = ({
  user,
  text,
  size,
  userType,
  className,
}) => {
  const names = user?.displayName.split(" ") ?? ["Unknown"];
  const firstInitial = names[0]?.[0]?.toUpperCase() ?? "U";
  const lastInitial = names[names.length - 1]?.[0]?.toUpperCase();
  let initials: string;
  if (lastInitial) {
    initials = `${firstInitial}${lastInitial}`;
  } else {
    initials = firstInitial;
  }

  return (
    <span
      className={cn(
        "relative bg-secondary",
        avatarVariants({ size, userType }),
        className
      )}
      style={{ backgroundColor: user?.color }}
    >
      {user?.isPresent && (
        <div className="absolute bottom-[-1px] right-[-1px] w-[30%] h-[30%] bg-additive rounded-full border border-background" />
      )}
      {text || (!!user ? initials : "?")}
    </span>
  );
};

export default OdoUserAvatar;
