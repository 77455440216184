import { useEditorRef } from "@udecode/plate-common";
import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import LoadableView from "components/common/containers/LoadableView";
import Rows from "components/common/containers/Rows";
import ContentLibraryItemPicker from "components/common/forms/ContentLibraryItemPicker";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Toggle from "components/common/forms/Toggle";
import Icon from "components/common/Icon";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/EditorView/Menus/Popover";
import useSctionDetails from "hooks/section-details/useSectionDetails";
import { cn } from "lib/utils";
import { doesSectionHaveContent } from "odo";
import { useConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface SectionDetailsPanelProps {
  className?: string;
}

const SectionDetailsPanel: React.FC<SectionDetailsPanelProps> = ({
  className,
}) => {
  const apiClient = useApiClient();
  const { details: proposalDetails } = useProposalData();
  const [isLoadingPastProposal, setIsLoadingPastProposal] = useState(false);
  const [useFixtures, setUseFixtures] = useState(false);
  const [tailored, setTailored] = useState(true);
  const [includeExistingContent, setIncludeExistingContent] = useState(true);
  const {
    id,
    name,
    headingLevel,
    baseContentItemId: sectionBaseContentItemId,
    pastProposal: sectionPastProposal,
    setPastProposal,
    details: sectionDetails,
    setDetails,
    setBaseContentItemId,
    contentEmbedding,
  } = useSctionDetails();
  const { isStaff } = useAuthenticatedUser();

  const [details, setLocalDetails] = useState(sectionDetails);
  const [pastProposal, setLocalPastProposal] = useState(sectionPastProposal);
  const [searchTerm, setSearchTerm] = useState("");
  const confirm = useConfirm();
  const editor = useEditorRef();

  useEffect(() => {
    setSearchTerm("");
    setLocalDetails(sectionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setLocalPastProposal(sectionPastProposal);
  }, [sectionPastProposal]);

  if (id === "") {
    return (
      <CenteredContainer className={className}>
        <Rows className="items-center gap-md text-secondary">
          <Icon name="memo-circle-info" size="xlarge" />
          <h1 className="font-semibold">Section Details</h1>
          <p>Select a section in the editor to view details about it.</p>
        </Rows>
      </CenteredContainer>
    );
  }

  const handleRefreshPastProposal = async () => {
    try {
      setIsLoadingPastProposal(true);
      const response = await apiClient.rfp.rfpProposalRelevantContextCreate(
        proposalDetails.id,
        { name: !!searchTerm ? searchTerm : name }
      );
      if (response.data.relevant_context) {
        setPastProposal(response.data.relevant_context);
      }
    } catch (e) {
      toast.error(
        "Failed to refresh past proposals: " + message_from_exception(e)
      );
    } finally {
      setIsLoadingPastProposal(false);
    }
  };

  const handleOpenSearch = () => {
    let url = "/admin/search";
    url += `?query=${encodeURIComponent(name)}`;
    url += `&strategy=forced_paragraphs`;
    url += `&org=${proposalDetails.orgId}`;
    url += `&topic=${proposalDetails.topic || "null"}`;
    window.open(url, "_blank");
  };

  const handleGenerate = async () => {
    try {
      if (doesSectionHaveContent(editor as any, id)) {
        if (
          !(await confirm("This section already has content", {
            yesText: "Discard and Generate",
            yesDestructive: true,
            noText: "Cancel",
            body: "When you generate content with a base, you will lose the existing content. Is that ok?",
          }))
        ) {
          return;
        }
      }
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: useFixtures,
          heading_level: headingLevel,
          include_previous_content: includeExistingContent,
          include_existing_content: false,
          tailored: tailored,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  };

  const handleGenerateOutline = async () => {
    try {
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: useFixtures,
          outline_only: true,
          heading_level: headingLevel,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  };

  let defaultSearchQuery: string | undefined;
  if (details && !contentEmbedding) {
    // If we have no embeddings, but we have details, use them as a search query
    defaultSearchQuery = `${name} ^ ${details}`;
  } else {
    defaultSearchQuery = name;
  }

  return (
    <Rows className={cn("p-md gap-md grow", className)}>
      <h1 className="font-semibold text-lg shrink-0">{name}</h1>
      <LabeledFormInput
        label="Details – Info on how to fill out this section"
        className="grow shrink-0 basis-0 overflow-hidden"
      >
        <NaturalHeightTextArea
          className="grow resize-none"
          value={details}
          onChange={(text) => setDetails(text)}
        />
      </LabeledFormInput>
      <LabeledFormInput
        label="Base"
        className="grow shrink-0 basis-0 overflow-hidden"
      >
        <ContentLibraryItemPicker
          proposalId={proposalDetails.id}
          selectedItemId={sectionBaseContentItemId}
          onSelect={(itemId) => setBaseContentItemId(itemId)}
          defaultSearchQuery={defaultSearchQuery}
          contentEmbedding={contentEmbedding}
        />
      </LabeledFormInput>
      <LoadableView
        isLoading={isLoadingPastProposal}
        className="grow shrink-0 basis-0 flex overflow-hidden"
      >
        <LabeledFormInput
          label={`Past Proposal (topic: ${proposalDetails.topic || "none"})`}
          className="grow relative"
        >
          <Input
            placeholder="Search term override"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <NaturalHeightTextArea
            className="grow resize-none"
            value={pastProposal}
            onChange={(text) => setPastProposal(text)}
          />
          <div className="absolute -top-sm right-0 flex gap-sm">
            <Button
              tooltip="Open in search admin"
              icon="magnifying-glass"
              onClick={handleOpenSearch}
            />
            <Button
              tooltip="Regenerate past proposal content"
              icon="refresh"
              onClick={handleRefreshPastProposal}
            />
          </div>
        </LabeledFormInput>
      </LoadableView>
      <Columns className="justify-end shrink-0">
        <Button
          variant="solid-secondary"
          icon="play"
          text="Outline"
          onClick={handleGenerateOutline}
          tooltip="Generate subsections for this section"
        />
        <div className="grow" />
        <Button
          variant="solid"
          icon="play"
          text="Generate"
          onClick={handleGenerate}
          className="Draft content for this section based on the details and past proposal info"
        />
        <Popover>
          <PopoverTrigger>
            <Icon name="gear" className="w-xl" />
          </PopoverTrigger>
          <PopoverContent>
            <Rows className="gap-sm items-start">
              {isStaff && (
                <Toggle
                  on={useFixtures}
                  onToggle={setUseFixtures}
                  text="Use Fixture"
                  toggleType="checkbox"
                />
              )}
              <Toggle
                on={includeExistingContent}
                onToggle={setIncludeExistingContent}
                text="Existing Content"
                toggleType="checkbox"
              />
              <Toggle
                on={tailored}
                onToggle={setTailored}
                text="Tailored Base"
                toggleType="checkbox"
              />
            </Rows>
          </PopoverContent>
        </Popover>
      </Columns>
    </Rows>
  );
};

export default SectionDetailsPanel;
