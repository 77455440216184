import { useEditorRef } from "@udecode/plate-common";
import { getPreviousSections } from "lib/plate/getPreviousSection";
import { SectionInfo } from "odo";
import { useChoose } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { ActiveSection } from "providers/RequirementContentEditorProvider";
import { useCallback } from "react";
import { Category } from "./useContentLibrary";
import { toast } from "react-toastify";

const useContentLibraryActions = (proposalId: string | null) => {
  const editor = useEditorRef();
  const apiClient = useApiClient();
  const choose = useChoose();
  const insertContent = useCallback(
    async (
      contentId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection,
      call: (
        detailsId: string,
        sectionId: string,
        contentId: string,
        level: number,
        location: string
      ) => Promise<any>
    ) => {
      if (!proposalId) {
        toast.error("No proposal selected");
        return;
      }

      let level = sectionNode.level;
      let location = activeSection.mode;

      if (activeSection.mode === "above") {
        const previousSections = getPreviousSections(
          activeSection.id,
          editor,
          1
        );
        if (previousSections && previousSections.length > 0) {
          level = previousSections[previousSections.length - 1].level;
        }
      }

      if (location === "active") {
        location = "below";
        level += 1;
      }

      await call(proposalId, sectionNode.id, contentId, level, location);
    },
    [editor, proposalId]
  );

  const insertProject = useCallback(
    async (
      projectId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      const mode = await choose("How would you like to incorporate this?", {
        dismissId: "cancel",
        choices: [
          {
            id: "as-is",
            text: "As Is",
            tooltip: "Add the project content without any changes",
          },
          {
            id: "tailored",
            text: "Tailored",
            tooltip: "Add the project and tailor it to the current RFP",
          },
          {
            id: "reference",
            text: "Reference",
            tooltip: "Add the project as a reference",
          },
        ],
      });

      if (mode === "cancel") {
        return;
      }

      await insertContent(
        projectId,
        sectionNode,
        activeSection,
        (proposalId, sectionId, projectId, level, location) =>
          apiClient.rfp.rfpContentLibraryInsertCreate(
            projectId,
            proposalId,
            sectionId,
            {
              section_depth: level,
              mode: mode as any,
              location: location as any,
            }
          )
      );
    },
    [apiClient.rfp, choose, insertContent]
  );

  const insertPerson = useCallback(
    async (
      personId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      await insertContent(
        personId,
        sectionNode,
        activeSection,
        (proposalId, sectionId, personId, level, location) =>
          apiClient.rfp.rfpContentLibraryInsertCreate(
            personId,
            proposalId,
            sectionId,
            {
              section_depth: level,
              location: location as any,
              mode: "as-is",
            }
          )
      );
    },
    [apiClient.rfp, insertContent]
  );

  const insertOverview = useCallback(
    async (
      overviewId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      await insertContent(
        overviewId,
        sectionNode,
        activeSection,
        (proposalId, sectionId, overviewId, level, location) =>
          apiClient.rfp.rfpContentLibraryInsertCreate(
            overviewId,
            proposalId,
            sectionId,
            {
              section_depth: level,
              mode: "as-is",
              location: location as any,
            }
          )
      );
    },
    [apiClient.rfp, insertContent]
  );

  const insertCoverLetter = useCallback(
    async (
      coverLetterId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      const mode = await choose("How would you like to incorporate this?", {
        dismissId: "cancel",
        choices: [
          {
            id: "as-is",
            text: "As Is",
            tooltip: "Add the cover letter content without any changes",
          },
          {
            id: "tailored",
            text: "Tailored",
            tooltip: "Add the cover letter and tailor it to the current RFP",
          },
        ],
      });

      if (mode === "cancel") {
        return;
      }

      await insertContent(
        coverLetterId,
        sectionNode,
        activeSection,
        (proposalId, sectionId, coverLetterId, level, location) =>
          apiClient.rfp.rfpContentLibraryInsertCreate(
            coverLetterId,
            proposalId,
            sectionId,
            {
              section_depth: level,
              mode: mode as any,
              location: location as any,
            }
          )
      );
    },
    [apiClient.rfp, choose, insertContent]
  );

  const insertSubcontractor = useCallback(
    async (
      subcontractorId: string,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      await insertContent(
        subcontractorId,
        sectionNode,
        activeSection,
        (proposalId, sectionId, subcontractorId, level, location) =>
          apiClient.rfp.rfpContentLibraryInsertCreate(
            subcontractorId,
            proposalId,
            sectionId,
            {
              section_depth: level,
              mode: "as-is",
              location: location as any,
            }
          )
      );
    },
    [apiClient.rfp, insertContent]
  );

  const insertItem = useCallback(
    async (
      id: string,
      category: Category,
      sectionNode: SectionInfo,
      activeSection: ActiveSection
    ) => {
      switch (category) {
        case "project":
          await insertProject(id, sectionNode, activeSection);
          break;
        case "person":
          await insertPerson(id, sectionNode, activeSection);
          break;
        case "overview":
          await insertOverview(id, sectionNode, activeSection);
          break;
        case "cover_letter":
          await insertCoverLetter(id, sectionNode, activeSection);
          break;
        case "subcontractor":
          await insertSubcontractor(id, sectionNode, activeSection);
      }
    },
    [
      insertCoverLetter,
      insertOverview,
      insertPerson,
      insertProject,
      insertSubcontractor,
    ]
  );

  return {
    insertProject,
    insertPerson,
    insertOverview,
    insertCoverLetter,
    insertItem,
  };
};

export default useContentLibraryActions;
