import { isOdoHeading } from "./types.js";
export const yjsProviderFromEditor = (editor) => {
    if (!("yjs" in editor)) {
        return null;
    }
    if (!("provider" in editor.yjs)) {
        return null;
    }
    if (!editor.yjs.provider) {
        return null;
    }
    return editor.yjs.provider;
};
export const doesSectionHaveContent = (editor, sectionId) => {
    let foundHeading = false;
    for (const child of editor.children) {
        if (isOdoHeading(child)) {
            if (foundHeading) {
                // Another heading found after the section heading
                // without having found any other content
                return false;
            }
            if (child.id === sectionId) {
                foundHeading = true;
                continue;
            }
        }
        else if (foundHeading) {
            // Found content after the section heading
            return true;
        }
    }
    if (!foundHeading) {
        console.warn("No section heading found", sectionId);
    }
    return false;
};
