import { ItemDetails } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface CoverLetterDetailsViewProps {
  coverLetterId: string;
  proposalId: string | null;
  onChanged: (person: ItemDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
  chooseItem?: () => Promise<void>;
}

const CoverLetterDetailsView: FC<CoverLetterDetailsViewProps> = ({
  coverLetterId,
  proposalId,
  onChanged,
  onSaveStateChanged,
  deleteItem,
  chooseItem,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpContentLibraryRead(coverLetterId, {
      // @ts-ignore
      query: { proposal_id: proposalId, kind: "cover_letter" },
    });
    return result.data;
  }, [coverLetterId]);

  return (
    <BaseItemDetailsView<ItemDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpContentLibraryUpdate(coverLetterId, details, {
          // @ts-ignore
          query: { proposal_id: proposalId },
        });
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      deleteItem={deleteItem}
      chooseItem={chooseItem}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Topic">
            <Input
              placeholder="The topic of this cover letter"
              value={details.topic ?? ""}
              onChange={(e) =>
                setDetails({ ...details, topic: e.target.value })
              }
            />
          </LabeledFormInput>
          <LabeledFormInput label="Writeup" className="grow overflow-hidden">
            <CollaborativeTextArea
              placeholder="A detailed writeup of the company focusing on this topic"
              className="h-[600px]"
              docId={details.content_id!}
              key={details.content_id}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default CoverLetterDetailsView;
