import useYjsMap from "api/useYjsMap";
import useYjsProvider from "hooks/yjs/useYjsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";

interface SectionDetails {
  details: string;
  pastProposal: string;
  baseContentItemId: string | null;
}

interface SectionDetailsResponse extends SectionDetails {
  id: string;
  name: string;
  headingLevel: number;
  contentEmbedding: number[] | null;

  setDetails: (details: string) => void;
  setPastProposal: (pastProposal: string) => void;
  setBaseContentItemId: (baseContentItemId: string | null) => void;
}

const useSctionDetails = (): SectionDetailsResponse => {
  const provider = useYjsProvider();

  const { activeSection } = useEditorDocData();
  const [allSections, setAllSections] = useYjsMap<SectionDetails>(
    provider,
    "sections",
    {},
    false
  );

  if (!activeSection || !activeSection.id)
    return {
      id: "",
      name: "",
      details: "",
      pastProposal: "",
      baseContentItemId: null,
      setDetails: () => {},
      setPastProposal: () => {},
      setBaseContentItemId: () => {},
      headingLevel: 0,
      contentEmbedding: null,
    };

  const setDetails = (details: string) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details,
        pastProposal: "",
        baseContentItemId: null,
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        details,
      });
    }
  };

  const setPastProposal = (pastProposal: string) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details: "",
        pastProposal,
        baseContentItemId: null,
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        pastProposal,
      });
    }
  };

  const setBaseContentItemId = (baseContentItemId: string | null) => {
    const prev = allSections[activeSection.id as string];
    if (!prev) {
      setAllSections(activeSection.id as string, {
        details: "",
        pastProposal: "",
        baseContentItemId,
      });
    } else {
      setAllSections(activeSection.id as string, {
        ...prev,
        baseContentItemId,
      });
    }
  };

  const section = allSections[activeSection.id as string];
  if (!section)
    return {
      id: activeSection.id,
      name: activeSection.name,
      headingLevel: activeSection.level,
      details: "",
      pastProposal: "",
      baseContentItemId: null,
      setBaseContentItemId,
      setDetails,
      setPastProposal,
      contentEmbedding: null,
    };

  return {
    name: activeSection.name,
    id: activeSection.id,
    headingLevel: activeSection.level,
    ...section,
    setDetails,
    setPastProposal,
    setBaseContentItemId,
    contentEmbedding: activeSection.contentEmbedding,
  };
};

export default useSctionDetails;
