import { ItemDetails } from "api/Api";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import MonthInput from "components/common/forms/MonthInput";
import Columns from "components/common/containers/Columns";

interface ProjectDetailsViewProps {
  projectId: string;
  proposalId: string | null;
  onChanged: (project: ItemDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
  chooseItem?: () => Promise<void>;
}

const ProjectDetailsView: FC<ProjectDetailsViewProps> = ({
  projectId,
  proposalId,
  deleteItem,
  onChanged,
  onSaveStateChanged,
  chooseItem,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpContentLibraryRead(projectId, {
      // @ts-ignore
      query: { proposal_id: proposalId, kind: "project" },
    });
    return result.data;
  }, [projectId]);

  return (
    <BaseItemDetailsView<ItemDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpContentLibraryUpdate(projectId, details, {
          // @ts-ignore
          query: { proposal_id: proposalId, kind: "project" },
        });
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      deleteItem={deleteItem}
      chooseItem={chooseItem}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Project Name">
            <Input
              placeholder="A short name for this project"
              value={details.title ?? ""}
              onChange={(e) =>
                setDetails({ ...details, title: e.target.value })
              }
            />
          </LabeledFormInput>
          <Columns className="gap-lg shrink-0">
            <LabeledFormInput label="Customer" className="grow basis-0">
              <Input
                placeholder="Who this project was for"
                value={details.customer ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, customer: e.target.value })
                }
              />
            </LabeledFormInput>
          </Columns>
          <Columns className="gap-md shrink-0">
            <LabeledFormInput label="Location" className="grow basis-0">
              <Input
                placeholder="Where this project took place"
                value={details.location ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, location: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput
              label="Project Start"
              className="grow basis-0 overflow-hidden"
            >
              <MonthInput
                className="grow basis-0 overflow-hidden"
                month={details.start_month ?? null}
                year={details.start_year ?? null}
                onChange={(value) => {
                  setDetails({
                    ...details,
                    start_month: value?.month,
                    start_year: value?.year,
                  });
                }}
              />
            </LabeledFormInput>
            <LabeledFormInput
              label="Project End"
              className="grow basis-0 overflow-hidden"
            >
              <MonthInput
                className="grow basis-0"
                month={details.end_month ?? null}
                year={details.end_year ?? null}
                onChange={(value) => {
                  setDetails({
                    ...details,
                    end_month: value?.month,
                    end_year: value?.year,
                  });
                }}
              />
            </LabeledFormInput>
          </Columns>
          <Columns className="gap-md shrink-0">
            <LabeledFormInput label="Contact Name" className="grow basis-0">
              <Input
                placeholder="Name"
                value={details.contact_name ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, contact_name: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Contact Title" className="grow basis-0">
              <Input
                placeholder="Title"
                value={details.contact_title ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, contact_title: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Contact Email" className="grow basis-0">
              <Input
                placeholder="Email"
                value={details.contact_email ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, contact_email: e.target.value })
                }
              />
            </LabeledFormInput>
          </Columns>

          <Columns className="gap-md shrink-0">
            <LabeledFormInput label="Contact Phone" className="grow basis-0">
              <Input
                placeholder="Phone"
                value={details.contact_phone ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, contact_phone: e.target.value })
                }
              />
            </LabeledFormInput>
            <LabeledFormInput label="Contact Address" className="grow basis-0">
              <Input
                placeholder="Address"
                value={details.contact_address ?? ""}
                onChange={(e) =>
                  setDetails({ ...details, contact_address: e.target.value })
                }
              />
            </LabeledFormInput>
          </Columns>

          <LabeledFormInput label="Case Study" className="grow overflow-hidden">
            {/* <NaturalHeightTextArea
                placeholder="A detailed description of this project"
                value={details.descriptio}
                className="min-h-[160px] px-md"
                onChange={(text) =>
                  setDetails({ ...details, description: text })
                }
              /> */}
            <CollaborativeTextArea
              placeholder="A detailed description of this project"
              className="h-[600px]"
              docId={details.content_id!}
              key={details.content_id}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default ProjectDetailsView;
