import { InputProps, inputVariants } from "./Input";
import Columns from "../containers/Columns";
import { cn } from "lib/utils";

interface MonthInputProps extends Omit<InputProps, "value" | "onChange"> {
  month: number | null;
  year: number | null;
  onChange: (value: { month: number | null; year: number | null }) => void;
}

const MonthInput: React.FC<MonthInputProps> = ({
  month,
  year,
  onChange,
  onKeyDown,
  onKeyUp,
  className,
  ...props
}) => {
  const setMonth = (value: string) => {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      onChange({ month: null, year });
    } else {
      onChange({ month: parsed, year });
    }
  };

  const setYear = (value: string) => {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      onChange({ month, year: null });
    } else {
      onChange({ month, year: parsed });
    }
  };

  return (
    <Columns
      className={cn(
        inputVariants({ variant: "default" }),
        "border rounded-md",
        className
      )}
      style={{
        ...props.style,
        padding: "0px",
      }}
      {...props}
    >
      <div className="grow basis-0 relative">
        <input
          placeholder="mm"
          className={cn(
            inputVariants({ variant: "default" }),
            "absolute inset-0 text-right border-none"
          )}
          value={month !== null ? month.toString() : ""}
          onChange={(e) => setMonth(e.target.value)}
        />
      </div>
      <Columns className="items-center">
        <p className="shrink-0 basis-0">/</p>
      </Columns>
      <div className="grow basis-0 relative">
        <input
          placeholder="yy"
          className={cn(
            inputVariants({ variant: "default" }),
            "absolute inset-0 border-none"
          )}
          value={year !== null ? year.toString() : ""}
          onChange={(e) => setYear(e.target.value)}
        />
      </div>
    </Columns>
  );
};

export default MonthInput;
