import Columns from "components/common/containers/Columns";
import NavigationView from "components/common/NavigationView";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Category, LibraryItem } from "hooks/useContentLibrary";
import ContentLibraryView from "components/content-library/ContentLibraryView";

const ContentLibraryRoute = () => {
  const { category, itemId } = useParams();
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [selectedItem, setSelectedItem] = useState<LibraryItem | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem) {
      navigate(`/content/${activeCategory}/${selectedItem.id}/`);
    } else {
      navigate(`/content/${activeCategory}/`);
    }
  }, [activeCategory, navigate, selectedItem]);

  return (
    <NavigationView
      selected="content-library"
      containerClassName="max-w-[1600px] w-full mx-auto"
      scrollable={false}
      fullWidth={true}
    >
      <h1 className="text-2xl font-semibold shrink-0 mb-md">Content Library</h1>
      <Columns className="grow border rounded-md relative">
        <ContentLibraryView
          proposalId={null}
          size="full"
          initialItemId={itemId}
          initialCategory={category as any}
          onCategoryChange={setActiveCategory}
          onSelectedItemChange={setSelectedItem}
        />
      </Columns>
    </NavigationView>
  );
};

export default ContentLibraryRoute;
